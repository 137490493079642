import RestService from './RestService';
import NotesService from './NotesService';

export default class Subjects extends RestService {
    constructor(parentService, parentId) {
        if (parentService && parentId) {
            super(`${parentService.baseUrl}/${parentId}/subjects`);
        } else super('/subjects');
    }

    all() {
        return this.get(`${this.baseUrl}/all`);
    }

    export(options) {
        return this.get(`${this.baseUrl}/export`, null, options);
    }

    import(formData) {
        const result = this.put(`${this.baseUrl}/import`, formData, {
            headers: {
                ...this.commonHeaders().headers,
                'Content-Type': 'multipart/form-data',
            },
        });
        return result;
    }

    superGet(id) {
        return this.get(`${this.baseUrl}/all/${id}`);
    }

    superGetLanguages(id) {
        return this.get(`${this.baseUrl}/all/${id}/languages`);
    }

    updateSubjectLanguageContent(id, lang, content) {
        return this.put(`${this.baseUrl}/all/${id}/languages/content/${lang}`, {
            content: content,
        });
    }

    updateSubjectLanguageTranslation(
        id,
        lang,
        title,
        description,
        videoJwId,
        videoThumb
    ) {
        return this.put(
            `${this.baseUrl}/all/${id}/languages/translation/${lang}`,
            {
                title: title,
                description: description,
                videoJwId,
                videoThumb,
            }
        );
    }

    createSubjectLanguageTranslation(id, lang) {
        return this.post(
            `${this.baseUrl}/all/${id}/languages/translation/${lang}`,
            {}
        );
    }

    updateSubjectLanguageQuestion(
        id,
        lang,
        question,
        correct,
        wrong1,
        wrong2,
        wrong3
    ) {
        return this.put(
            `${this.baseUrl}/all/${id}/languages/question/${lang}`,
            {
                question: question,
                correct: correct,
                wrong1: wrong1,
                wrong2: wrong2,
                wrong3: wrong3,
            }
        );
    }

    notesService(subjectId) {
        return new NotesService(this, subjectId);
    }

    getVideoPlayerUrl() {
        return this.post(`${this.baseUrl}/videoPlayerUrl`);
    }

    getVideoToken(videoId) {
        return this.post(`${this.baseUrl}/videoToken`, { videoId });
    }

    getScormInfo(subjectId) {
        return this.post(`${this.baseUrl}/scormInfo`, {
            subjectId,
            preview: true,
        });
    }

    getCategories(subjectId) {
        return this.get(`${this.baseUrl}/all/${subjectId}/categories`);
    }

    addCategory(categoryId, subjectId) {
        return this.post(`${this.baseUrl}/${subjectId}/categories`, {
            categoryId,
        });
    }

    deleteCategory(categoryId, subjectId) {
        return this.del(
            `${this.baseUrl}/${subjectId}/categories/${categoryId}`
        );
    }

    getTemplates(subjectId) {
        return this.get(`${this.baseUrl}/all/${subjectId}/templates`);
    }

    addTemplate(templateId, subjectId) {
        return this.post(`${this.baseUrl}/${subjectId}/templates`, {
            templateId,
        });
    }

    deleteTemplate(templateId, subjectId) {
        return this.del(`${this.baseUrl}/${subjectId}/templates/${templateId}`);
    }

    async makeCopy(subjectId) {
        return this.post(`${this.baseUrl}/${subjectId}/makeCopy`, {
            subjectId,
        });
    }

    deleteLanguage(subjectId, language) {
        return this.del(`${this.baseUrl}/${subjectId}/language/${language}`);
    }
}
