import RestService from './RestService';

export default class TemplatesService extends RestService {
    constructor(parentService, parentId) {
        if (parentService && parentId) {
            super(`${parentService.baseUrl}/${parentId}/questions`);
        } else super('/questions');
    }

    async recalculateQuestionsMaxScore() {
        await this.post(`${this.baseUrl}/recalculate-max-scores`, {});
        return true;
    }

    async makeCopy(questionId) {
        return this.post(`${this.baseUrl}/${questionId}/makeCopy`, {
            questionId,
        });
    }
}
