import React, { useEffect, useState, Fragment } from 'react';
import { observer } from 'mobx-react';
import ErrorMessage from '../../components/errormessage';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { useStores } from '../../hooks/use-stores';
import ACLEditor from './ACLEditor';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import TagsEditor from './TagsEditor';

const useStyles = makeStyles(() => ({
    language: {
        filter: 'grayscale(1)',
        opacity: 0.3,
        cursor: 'pointer',
    },

    selectedLanguage: {
        filter: 'grayscale(0)',
        opacity: 1,
    },
}));
function Question() {
    let { questionId } = useParams();
    const { superStore } = useStores();
    const classes = useStyles();
    const {
        loadingQuestion,
        editingQuestion,
        editingQuestionDefinition,
        sortedlanguages: languages,
        questionsError,
        updatingQuestions,
        creatingQuestion,
    } = superStore;
    const [nameError, setNameError] = useState(false);
    const [definitionError, setDefinitionError] = useState(false);
    let history = useHistory();
    let match = useRouteMatch();
    const selectedLanguages = editingQuestion
        ? editingQuestion.languages || ['en']
        : [];

    const loadData = async () => {
        await superStore.loadLanguages();
        questionId
            ? await superStore.loadQuestion(questionId)
            : (superStore.editingQuestion = {
                  name: '',
                  definition: '',
                  tags: [],
              });
    };

    useEffect(() => {
        loadData();
    }, [questionId]);

    const toggleLanguage = (code) => {
        if (!editingQuestion.languages) {
            editingQuestion.languages = selectedLanguages;
        }
        if (editingQuestion.languages.includes(code)) {
            editingQuestion.languages = editingQuestion.languages.filter(
                (lang) => lang !== code
            );
        } else {
            editingQuestion.languages.push(code);
        }
    };

    const makeCopy = async () => {
        const result = await superStore.makeQuestionCopy(questionId);
        if (result && result.newId) {
            history.push(`/content/questions/${result.newId}/edit`);
        }
    };

    const handleChange = (e) => {
        superStore.setDefinition(e.target.value);
    };
    const handleSave = async () => {
        if (!superStore.editingQuestion.name) {
            setNameError(true);
            return;
        }
        setNameError(false);
        try {
            JSON.parse(editingQuestionDefinition);
            setDefinitionError(false);
        } catch (e) {
            setDefinitionError(true);
        }
        let result;
        questionId
            ? (result = await superStore.updateQuestion(
                  superStore.editingQuestion.id,
                  {
                      ...superStore.editingQuestion,
                      definition: editingQuestionDefinition,
                  }
              ))
            : (result = await superStore.createQuestion({
                  ...superStore.editingQuestion,
                  max_score: 0,
                  definition: editingQuestionDefinition,
              }));
        if (result) {
            questionId
                ? history.push(`/content/questions/${questionId}/edit`)
                : history.push('/content/questions');
        }
    };

    return loadingQuestion || !editingQuestion ? (
        <ThemedSpinner />
    ) : (
        editingQuestion && (
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                align="center"
                spacing={2}
            >
                <Grid item xs={12}>
                    <Box mt={4}>
                        <Typography variant="h2" gutterBottom>
                            Question: {editingQuestion.name}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} justify={'flex-end'}>
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={makeCopy}
                    >
                        Make Copy
                    </Button>
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        type="text"
                        name="questionName"
                        value={editingQuestion.name || ''}
                        onChange={(e) => {
                            editingQuestion.name = e.target.value;
                        }}
                        error={nameError}
                        helperText={nameError ? 'Name is required' : ''}
                        variant="outlined"
                        label="Question name"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={10} style={{ textAlign: 'left' }}>
                    <FormControl variant="filled" fullWidth>
                        <InputLabel>Difficulty</InputLabel>
                        <Select
                            variant="filled"
                            name="difficulty"
                            value={editingQuestion.difficulty || ''}
                            onChange={(e) => {
                                editingQuestion.difficulty = e.target.value;
                            }}
                        >
                            <MenuItem value="basic">basic</MenuItem>
                            <MenuItem value="medium">medium</MenuItem>
                            <MenuItem value="advanced">advanced</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        type="text"
                        name="areas"
                        value={editingQuestion.area || ''}
                        onChange={(e) => {
                            editingQuestion.area = e.target.value || '';
                        }}
                        variant="outlined"
                        label="Threat area"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        type="text"
                        name="areas"
                        value={editingQuestion.components || ''}
                        onChange={(e) => {
                            editingQuestion.components = e.target.value || '';
                        }}
                        variant="outlined"
                        label="Components"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        type="text"
                        name="thumbnail"
                        value={editingQuestion.thumbnail || ''}
                        onChange={(e) => {
                            editingQuestion.thumbnail = e.target.value || '';
                        }}
                        variant="outlined"
                        label="Thumbnail"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        type="text"
                        name="tags"
                        value={(editingQuestion.tags || []).join(', ') || ''}
                        onChange={(e) => {
                            editingQuestion.tags = (e.target.value || '')
                                .split(',')
                                .map((a) => a.trim());
                        }}
                        variant="outlined"
                        label="Tags (comma separated)"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        type="textarea"
                        multiline
                        rows={3}
                        cols={70}
                        name="questionDefinition"
                        value={editingQuestion.adminDescription || ''}
                        onChange={(e) => {
                            editingQuestion.adminDescription = e.target.value;
                        }}
                        variant="outlined"
                        label="Description for LMS"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        type="textarea"
                        multiline
                        rows={15}
                        cols={70}
                        name="questionDefinition"
                        value={editingQuestionDefinition || ''}
                        onChange={handleChange}
                        error={definitionError}
                        helperText={
                            definitionError
                                ? 'Should be a valid JSON: ' + definitionError
                                : ''
                        }
                        variant="outlined"
                        label="Definition"
                        fullWidth
                    />
                </Grid>
                <TagsEditor
                    editingItem={editingQuestion}
                    targetType={'video'}
                />

                <Grid item xs={10} container spacing={1}>
                    {languages.map((l) => {
                        return (
                            <Grid
                                key={l.id}
                                item
                                xs={3}
                                className={clsx(classes.language, {
                                    [classes.selectedLanguage]:
                                        selectedLanguages.includes(l.code),
                                })}
                                onClick={() => toggleLanguage(l.code)}
                            >
                                <Avatar
                                    alt="country flag"
                                    src={`${l.flag_image}`}
                                />
                                {l.name}
                            </Grid>
                        );
                    })}
                </Grid>
                <Grid item xs={12}>
                    <Box mb={match.path.includes('edit') ? 0 : 4}>
                        {creatingQuestion ||
                        (questionId &&
                            updatingQuestions.includes(questionId)) ? (
                            <ThemedSpinner />
                        ) : (
                            <Button
                                onClick={handleSave}
                                color="primary"
                                variant="contained"
                            >
                                Save
                            </Button>
                        )}
                    </Box>
                </Grid>
                {match.path.includes('edit') ? (
                    <Fragment>
                        <Grid item xs={12}>
                            <Box mt={2}>
                                <Typography variant="h4" gutterBottom>
                                    Access control
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <ACLEditor
                                acl={editingQuestion.acl}
                                unique_id={editingQuestion.unique_id}
                            />
                        </Grid>
                    </Fragment>
                ) : (
                    ''
                )}

                {questionsError && (
                    <Grid item xs={12}>
                        <ErrorMessage error={questionsError} />
                    </Grid>
                )}
            </Grid>
        )
    );
}

export default observer(Question);
